.App {
  
  /* We first create a flex layout context */
  display: flex;
  
  /* Then we define the flow direction 
     and if we allow the items to wrap 
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
  flex-flow: column wrap;
  
  /* Then we define how is distributed the remaining space */
  justify-content: space-around;
  
  /* padding: 5px; */
  /* margin: 10px; */
  list-style: none;
  /* align-items: center; */
  background: rgb(248, 248, 248);
}

.App-code {
  padding: 10px;
  width: 75%;
  display: block;
  word-wrap: break-word;
  background: lightgray;
}

.App-pre {
  padding: 10px;
  width: 75%;
  display: block;
  word-wrap: break-word;
  background: lightgray;
}

.App-token{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.App-copy-token{
  padding: 10px;
}

.copy-feedback {
  margin-left: 1rem;
  visibility: hidden;
}

.copy-feedback.active {
  visibility: visible;
  color: green;
  font-weight: bold;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 1vmin);
  color: white;
  width: 100%;
  /* padding: 10px; */
}

.App-header-2 {
  background-color: #494c52;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 1vmin);
  color: white;
  width: 100%;
  /* padding: 10px; */
}

.App-hr{
  height:2px;
  border-width:0;
  color:gray;
  background-color:gray; 
  width: 75%;
}

.App-login {
  margin: 10px;  
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
